<template>
  <b-card no-body>
    <!-- eslint-disable -->
    <b-card-header class="pb-50">
      <h5>
        Filtros
        <template v-if="!see_more">
          <a
            class="control-label font-weight-bold text-info font-custom"
            @click="clickSeeMore"
            ><strong> [+ Ver más]</strong></a
          >
        </template>
        <template v-else>
          <a
            class="control-label font-weight-bold text-info font-custom"
            @click="clickSeeMore"
            ><strong> [- Ver menos]</strong></a
          >
        </template>
      </h5>
      <div class="d-flex align-items-center justify-content-end">
        <!-- @click="isAddNewUserSidebarActive = true"< -->
        <b-button variant="primary" class="mb-25" @click="toAdd">
          <span class="text-nowrap">Agregar elemento</span>
        </b-button>
      </div>
    </b-card-header>
    <b-card-body v-if="see_more">
        <b-form>
          <b-row>
            <b-col md="3" class="mb-md-0 mb-2">
              <!-- @input="(val) => $emit('update:roleFilter', val)" -->
                <label>Nombre</label>
                <b-form-input @keypress="isLetter" v-model="nameFilter" placeholder="Nombre" />
            </b-col>
            <b-col md="3" class="mb-md-0 mb-2">
              <!-- @input="(val) => $emit('update:roleFilter', val)" -->
              <label>Apellidos</label>
              <b-form-input @keypress="isLetter" v-model="lastNameFilter" placeholder="Apellidos" />
            </b-col>
            <b-col md="3" class="mb-md-0 mb-2">
              <!-- @input="(val) => $emit('update:roleFilter', val)" -->
              <label>Email</label>
              <b-form-input v-model="emailFilter" placeholder="Email" />
            </b-col>
            <b-col md="3" class="mb-md-0 mb-2">
              <!-- @input="(val) => $emit('update:roleFilter', val)" -->
              <label>Rol</label>
              <v-select
                v-model="roleFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-col>
            <b-col md="6" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="filter"
              >
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Buscar</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-1"
                @click.prevent="clean"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span class="align-middle">Limpiar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable*/
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      see_more: false,
      nameFilter: "",
      lastNameFilter: "",
      emailFilter: "",
      roleFilter: null,
    };
  },
  components: {
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BForm
  },
  mounted() {
    console.log("this.parent", this);
  },
  methods: {
    toAdd() {
      this.$router.push("/users/add");
    },
    clickSeeMore() {
      this.see_more = !this.see_more;
    },
    async filter() {
      //armar la estructura del api
      var arrayFilter = [];
      if (this.nameFilter != "") {
        arrayFilter.push({
          keyContains: "name",
          key: "contains",
          value: this.nameFilter,
        });
      }
      if (this.lastNameFilter != "") {
        arrayFilter.push({
          keyContains: "last_name",
          key: "contains",
          value: this.lastNameFilter,
        });
      }
      if (this.emailFilter != "") {
        arrayFilter.push({
          keyContains: "email",
          key: "contains",
          value: this.emailFilter,
        });
      }
      if (this.roleFilter != null) {
        arrayFilter.push({
          keyContains: "role",
          key: "equals",
          value: this.roleFilter,
        });
      }
      console.log("arrayFilter", arrayFilter);
      this.$parent.arrayFilters = arrayFilter;
      await new Promise((resolve) => setTimeout(resolve, 100));
      this.$parent.getData();
    },
    clean() {
      this.nameFilter = "";
      this.lastNameFilter = "";
      this.emailFilter = "";
      this.roleFilter = null;
      var arrayFilter = [];
      this.$parent.arrayFilters = arrayFilter;
      this.$parent.getData()
    },
    isLetter(event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        event.preventDefault();
      }
    },
  },
  props: {
    roleOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
